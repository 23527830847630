import { Brand } from '@lendi/lala-react';

export const getBrand = () => {
  if (!window) return Brand.Lendi;
  const hostname = window.location.hostname;
  if (!process.env.REACT_APP_ENV) {
    const brand = window.localStorage.getItem('brand');
    switch (brand) {
      case 'aussie':
        return Brand.Aussie;
      default:
        return Brand.Lendi;
    }
  }
  if (hostname.includes('aussie')) return Brand.Aussie;
  return Brand.Lendi;
};
