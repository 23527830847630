import { Environment } from '@lendi/lala-react';

export const getEnv = () => {
  switch (process.env['REACT_APP_ENV']) {
    case 'staging':
      return Environment.Staging;
    case 'preproduction':
      return Environment.Preproduction;
    case 'production':
      return Environment.Production;
    default:
      return Environment.Development;
  }
};
