import { Brand } from '@lendi/lala-react';
import { IconProps } from '@lendi-ui/icon';
import InsertChart from '@lendi-ui/icon/InsertChart';
import Search from '@lendi-ui/icon/Search';
import BorderColor from '@lendi-ui/icon/BorderColor';
import HomeAccount from '@lendi-ui/icon/HomeAccount';
import LockOpen from '@lendi-ui/icon/LockOpen';
import RefinanceHome from '@lendi-ui/icon/RefinanceHome';
import AttachMoney from '@lendi-ui/icon/AttachMoney';
import Queue from '@lendi-ui/icon/Queue';

import { getBaseUrl } from './getBaseUrl';
import { getBrand } from './getBrand';
import { getEnv } from './getEnv';

const brand = getBrand();
const env = getEnv();

export type Tool = {
  key: string;
  label: string;
  Icon: (props: IconProps) => JSX.Element;
  url: string;
};

export type Tools = {
  key: string;
  category: string;
  items: Tool[];
};

const commonTools: Tools = {
  key: 'broker-tools',
  category: 'Broker Tools',
  items: [
    {
      key: 'funding-position',
      label: 'Initial Funding\nPosition',
      Icon: InsertChart,
      url: `${getBaseUrl(brand, env)}/funding-position`,
    },
    {
      key: 'product-search',
      label: 'Product\nSearch',
      Icon: Search,
      url: `${getBaseUrl(brand, env)}/product-search`,
    },
  ],
};

export const getBrandTools = (brand: Brand): Tools[] => {
  if (brand === Brand.Aussie) {
    return [
      {
        ...commonTools,
        items: [
          ...commonTools.items,
          {
            key: 'one-span',
            label: 'OneSpan',
            Icon: BorderColor,
            url: `https://launcher.myapps.microsoft.com/api/signin/b55068f0-6808-4c2e-914a-76101e84e2ec?tenantId=e79acc8b-602b-4d8c-af23-fb8640740496`,
          },
        ],
      },
      {
        key: 'calculators',
        category: 'Calculators',
        items: [
          {
            key: 'borrowing-power',
            label: 'Borrowing Power Calculator',
            Icon: HomeAccount,
            url: `${getBaseUrl(Brand.Aussie, env, true)}/calculators/borrowing-power`,
          },
          {
            key: 'fixed-rate-expiry',
            label: 'Fixed Rate Expiry Calculator',
            Icon: LockOpen,
            url: `${getBaseUrl(Brand.Aussie, env, true)}/calculators/fixed-rate-expiry`,
          },
          {
            key: 'mortgage-repayments',
            label: 'Repayments Calculator',
            Icon: RefinanceHome,
            url: `${getBaseUrl(Brand.Aussie, env, true)}/calculators/mortgage-repayments`,
          },
          {
            key: 'mortgage-deposit-calculator',
            label: 'Deposit\nCalculator',
            Icon: AttachMoney,
            url: `${getBaseUrl(Brand.Aussie, env, true)}/calculators/mortgage-deposit-calculator`,
          },
          {
            key: 'mortgage-extra-repayment-calculator',
            label: 'Extra Repayments Calculator',
            Icon: Queue,
            url: `${getBaseUrl(Brand.Aussie, env, true)}/calculators/mortgage-extra-repayment-calculator`,
          },
        ],
      },
    ];
  }
  return [commonTools];
};
