import { Brand, Environment, Session, Type } from '@lendi/lala-react';

let session: Session;

export const getSession = (brand: Brand, environment: Environment, type: Type = Type.Team) => {
  if (!session) {
    session = new Session({
      type,
      brand,
      environment,
    });
  }
  return session;
};
